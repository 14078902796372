import React from "react";

import "../../App.css";
import "./CyberSecurity.css";

function DigiTrans() {
  return (
    <div>
      <section id="startchange" className="page-title">
        <div className="auto-container">
          <h1>Cyber Security</h1>
          <ul className="page-breadcrumb">
            <li className="firstLi">
              <a href="./">Home</a>
            </li>
            <li>Cyber Security</li>
          </ul>
        </div>
        <div className="custom-shape-divider-bottom-1607429966">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              className="shape-fill"
            ></path>
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              className="shape-fill"
            ></path>
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </section>

      <section className="services-page-section container-fluid">
        <div
          style={{ maxWidth: "1000px" }}
          className="auto-container container text-center"
        >
          <h3>Our Cyber Security Services</h3>
          <p>
            Our services help both the big and small organisations in the
            identification of security breaches and provide outclass solutions
            to prevent any potential harm. Our skilled team, incorporation of
            advanced technology and provision of customized security solutions
            make us a reliable choice for protecting your network.
          </p>
        </div>
      </section>

      <section className="what_we_offer">
        <div className="jumbotron-fluid mb-0">
          {/* <div className="row"> */}
          {/* <div className="container"> */}
          <div className="row mb-2 pt-0 row1">
            <div className="col-md-5 col-sm-12 mr-auto">
              <div className="header">
                <h4 className="display-5">Remote Monitoring</h4>
                <p className="lead my-3">
                  The most important part of any security control is measurable
                  effectiveness. Optisource offers a robust monitoring solution
                  for organisations of all sizes to continuously gauge the
                  health of their controls. We are authorised partners with the
                  best name in the game SolarWinds TM. <br />
                  This allows us to work with an organisation to define the most
                  applicable solution and offer a flexible cost model. We
                  operate a cutting-edge security operation centre which
                  operates on a 24/7 basis looking out for threats to your most
                  prized IT assets.
                  <br /> You can be rest assured your assets are well protected.
                  Protect your networks with 24X7 efficient, unmatched and
                  dedicated threat detection and quick response service.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12"
              data-aos="fade-left"
              data-aos-offset="100"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
            >
              <img
                src="./images/remote-monitoring.png"
                // src="https://themes.jibdara.com/vaximo/wp-content/uploads/2020/08/banner-site-img-2.png"
                className="img-fluid text-center"
                alt="web"
              />
            </div>
          </div>
          {/* </div> */}

          {/* <div className="container"> */}
          <div className="row my-2 row1">
            <div
              className="col-md-6 col-sm-12"
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
            >
              <img
                src="./images/penetration-testing.png"
                className="img-fluid"
                alt="QA"
              />
            </div>
            <div className="col-md-5 col-sm-12 ml-auto">
              <div className="header">
                <h4 className="display-5">Penetration Testing</h4>
                <p className="lead my-3">
                  An ethical assessment of cyber security controls is required
                  to mitigate constant cyber security threats. It must be
                  carefully designed for the sake of identifying and fixing both
                  the major and minor vulnerabilities.
                  <br />
                  Penetration testing also commonly known as the “Pen Test” aims
                  to keep your applications, websites, networks, and systems
                  safe from potential harm by simulating a real attack. We can
                  assess your infrastructure just alike a hacker would.
                </p>
              </div>
            </div>
          </div>
          {/* </div> */}

          {/* <div className="container"> */}
          <div className="row my-2 row1">
            <div className="col-md-5 col-sm-12 mr-auto">
              <div className="header">
                <h4 className="display-5">Vulnerability Assessment</h4>
                <p className="lead my-4">
                  A vulnerability assessment is the definition, identification,
                  classification and prioritization of vulnerabilities in
                  computer systems, applications and network infrastructures.
                  This provides the organization doing the assessment with the
                  necessary knowledge, awareness and risk background to
                  understand the threats to its infrastructure. <br />
                  Every organisation should perform an assessment regardless of
                  size. In some industries this is a compliance requirement. We
                  offer a comprehensive vulnerability assessment service.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12"
              data-aos="fade-left"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              data-aos-duration="500"
            >
              <img
                src="./images/vulnerability-assessment.png"
                className="img-fluid"
                alt="digital"
              />
            </div>
          </div>
          {/* </div> */}

          {/* <div className="container"> */}
          <div className="row my-2 row1">
            <div
              className="col-md-6 col-sm-12 ml-auto"
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
            >
              <img
                src="./images/cloud-migration-security.png"
                className="img-fluid"
                alt="QA"
              />
            </div>
            <div className="col-md-5 col-sm-12 mr-auto">
              <div className="header">
                <h4 className="display-5">Cloud Migration Security</h4>
                <p className="lead my-3">
                  An incredibly increased remote workforce, quick digitization,
                  and the quest for flexibility, comfort, and speed have added
                  to the credibility and significance of cloud. Almost all the
                  businesses in different industries are now opting for cloud
                  adoption. With time, more companies are choosing the
                  multi-cloud and hybrid environment and expanding. <br />
                  Consequently, these segmented networks are raising the
                  cybersecurity risks companies are exposed to. Let Optisource
                  take the pain out of migrating to the cloud. Our advanced
                  level security solutions can assist in dynamic threat
                  visibility for detecting and responding to all the major and
                  minor attacks that can affect your cloud infrastructure.
                </p>
              </div>
            </div>
          </div>
          {/* </div> */}

          {/* <div className="container"> */}
          <div className="row my-2 row1">
            <div className="col-md-5 col-sm-12 mr-auto">
              <div className="header">
                <h4 className="display-5">Cyber Security Awareness Training</h4>
                <p className="lead my-4">
                  Users continue to be the weakest link in the fight against
                  cybersecurity threats. Your employees are soft targets for
                  social engineering attacks such as phishing, “smishing”,
                  business email compromise etc. <br />
                  The only way to mitigate such threats is to run extensive user
                  awareness campaigns. Optisource are well equipped to train
                  your workforce either onsite or offsite at our
                  state-of-the-art facilities.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12"
              data-aos="fade-left"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              data-aos-duration="500"
            >
              <img
                src="./images/cyberSecurity-awareness-training.png"
                className="img-fluid"
                alt="digital"
              />
            </div>
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>
      </section>

      <section className="call-to-action">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="text-column col-lg-7 col-md-12 col-sm-12 col-xs-12">
              <div className="inner">
                <h2>START SECURING YOUR DATA NOW WITH OPTISOURCE!</h2>
                <div className="text">
                  Let's talk about your security need. What are you waiting for?
                </div>
              </div>
            </div>

            <div className="links-column col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <div className="inner">
                {/* <a href="#/about-us" className="theme-btn btn-style-one">
                  Learn More
                </a> */}
                <a href="#/contact-us" className="theme-btn btn-style-one">
                  Let's Talk{" "}
                  <span className="icon ti-arrow-circle-right"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DigiTrans;
