import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import AboutUs from "./Components/AboutUs/AboutUs";
import ContactUs from "./Components/ContactUs/ContactUs";
import DigiTrans from "./Components/DigiTrans/DigiTrans";
import Blog from "./Components/Blog/Blog";
import BlogPost from "./Components/Blog/BlogDetail";
import Home from "./Components/Home";
import CyberSecurity from "./Components/CyberSecurity/CyberSecurity";

import BlogData from "./Components/Blog/Blog.json";
import ScrollToTop from "./Components/ScrollToTop";

import "./App.css";

function App() {
  const blogData = [...BlogData];
  return (
    <div className="App">
      <Router>
        <Navbar />
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/cyber-security" component={CyberSecurity} />
          <Route path="/digital-transformation" component={DigiTrans} />
          <Route path="/blog" component={Blog} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/:id">
            <BlogPost data={blogData} />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
