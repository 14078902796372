import "../../App.css";

function Footer() {
  return (
    <div>
      <footer class="footer-area footer--light">
        <div class="footer-big">
          <div class="container">
            {/* <div className="row">             
            </div> */}
            <div class="row">
              <div class="col-md-3 col-sm-12">
                <div class="footer-widget">
                  <div class="widget-about">
                    <img
                      src="./images/logoWhiteBg.png"
                      alt="Optisource logo"
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-12 text-sm-center text-md-left">
                <div class="footer-widget">
                  <div class="footer-menu footer-menu--1">
                    <h4 class="footer-widget-title">Our Mission</h4>
                    <p>
                      Making Technology Work For Business. Enabling people and
                      businesses to achieve their full potential.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-12 text-sm-center text-md-left">
                <div class="footer-widget">
                  <div class="footer-menu">
                    <h4 class="footer-widget-title">Useful Links</h4>
                    <ul>
                      <li>
                        <a href="#/digital-transformation">
                          Digital Transformation
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-md-3 col-sm-12 text-sm-center text-md-left">
                <div class="footer-widget">
                  <div class="footer-menu no-padding">
                    <h4 class="footer-widget-title">Contact Us</h4>
                    <ul class="contact-details">
                      {/* <li>
                        <span class="icon-earphones">
                          <i class="fas fa-phone-alt"></i>
                        </span>{" "}
                        Call Us: <a href="tel:0800-010-6049">0800-010-6049</a>
                      </li> */}
                      <li>
                        <span class="icon-envelope-open">
                          <i class="fas fa-envelope"></i>
                        </span>{" "}
                        <a href="mailto:Contact@optisourcetech.com">
                          Contact@optisourcetech.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mini-footer">
          <div className="container">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-7 col-sm-12 text-md-left">
                <div className="copyright-text">
                  <p>
                    © 2023 Optisource Technology Solutions All rights reserved.
                    Created by Optisource
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-sm-12 text-md-center">
                <a href="/">
                  {" "}
                  <i class="fab fa-twitter-square"></i>
                </a>
                <a href="/">
                  {" "}
                  <i class="fab fa-facebook-square"></i>
                </a>
                <a href="/">
                  {" "}
                  <i class="fab fa-instagram-square"></i>
                </a>
                <a href="/">
                  {" "}
                  <i class="fab fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
