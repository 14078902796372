/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, withRouter } from "react-router-dom";

import "../../App.css";

class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.listener = null;
    this.state = {
      status: "top",
    };
  }
  componentDidMount() {
    this.listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 100) {
        if (this.state.status !== "bottom") {
          this.setState({ status: "bottom" });
        }
      } else {
        if (this.state.status !== "top") {
          this.setState({ status: "top" });
        }
      }
    });
  }

  componentDidUpdate() {
    document.removeEventListener("scroll", this.listener);
  }

  render() {
    const { location } = this.props;

    const homeClass = location.pathname === "/" ? "active" : "";
    const aboutClass = location.pathname === "/about-us" ? "active" : "";
    const digitalTransformationClass =
      location.pathname === "/digital-transformation" ? "active" : "";
    const blogClass = location.pathname === "/blog" ? "active" : "";
    const contactClass = location.pathname === "/contact-us" ? "active" : "";
    const cyberSecurityClass =
      location.pathname === "/cyber-security" ? "active" : "";

    return (
      <nav
        className="navbar navbar-default navbar-expand-lg navbar-light fixed-top"
        style={{
          backgroundColor: this.state.status === "top" ? "transparent" : "#fff",
          boxShadow:
            this.state.status === "top" ? "none" : "0 3px 6px #0000002b",
          transition: "all 0.5s ease",
        }}
      >
        <div className="container">
          <a className="navbar-brand" href="#">
            <img
              src="./images/logoWhiteBg.png"
              className="img-fluid logo"
              width="30"
              height="30"
              alt="Optisource logo"
              loading="lazy"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav ml-auto">
              <a className={`nav-link ${homeClass}`} href="#">
                <Link to="/">
                  Home <span className="sr-only">(current)</span>
                </Link>
              </a>
              <a className={`nav-link ${aboutClass}`} href="#">
                <Link to="/about-us">About Us</Link>
              </a>
              <a className={`nav-link ${cyberSecurityClass}`} href="#">
                <Link to="/cyber-security">Cyber Security</Link>
              </a>
              <a className={`nav-link ${digitalTransformationClass}`} href="#">
                <Link to="/digital-transformation">Digital Transformation</Link>
              </a>
              <a className={`nav-link ${blogClass}`} href="#">
                <Link to="/blog">Blog</Link>
              </a>
              <a className={`nav-link ${contactClass}`} href="#">
                <Link to="/contact-us">Contact Us</Link>
              </a>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default withRouter(Navbar);
