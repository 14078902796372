import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import BlogData from "./Blog.json";

import "./BlogDetail.css";

function BlogPost({ data }) {
  const { id } = useParams();
  const post = data.find((p) => p.title === id);
  let nextId = post === undefined ? 1 : post.id + 1;
  const nextPost = data.find((p) => p.id === nextId);
  const [postdata, setPostData] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      return setPostData(BlogData);
    }
    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <div className="blog-post">
      <section id="startchange" className="container sidebar-page-container">
        <div className="row">
          <div className="content-side col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <section className="blog blog-details">
              {post ? (
                <div className="news-style-one">
                  <div className="inner-box">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <figure className="image-box">
                          <img src={post.img} alt={post.title} />
                        </figure>
                      </div>
                      <div className="sidebar-side col-lg-4 col-md-4 col-sm-12 col-xs-12 d-none d-md-block">
                        <aside className="row sidebar">
                          <div className="recent-post sidebar-widget">
                            <div className="title">
                              <h3>Recent Post</h3>
                            </div>
                            {postdata ? (
                              // eslint-disable-next-line array-callback-return
                              postdata.map((data, i) => {
                                if (i < 3) {
                                  return (
                                    <div key={i}>
                                      <article className="post">
                                        <figure className="post-thumb">
                                          <Link to={`/${data.title}`}>
                                            <img
                                              src={data.img}
                                              alt="data.title"
                                            />
                                          </Link>
                                        </figure>
                                        <h4>
                                          <Link to={`/${data.title}`}>
                                            {data.title}
                                          </Link>
                                        </h4>
                                        <div className="post-info">
                                          {data.author}
                                        </div>
                                      </article>
                                    </div>
                                  );
                                }
                              })
                            ) : (
                              <div className="col-12">
                                <p>
                                  No Content at the moment. Please check back
                                  later
                                </p>
                              </div>
                            )}
                          </div>
                        </aside>
                      </div>
                    </div>
                    <div className="lower-content">
                      <div className="post-meta">{post.author}</div>
                      <h3>{post.title}</h3>
                      <div className="text">
                        {post.content ? (
                          post.content.map((post, i) => (
                            <React.Fragment>
                              <p key={i}>{post}</p>
                            </React.Fragment>
                          ))
                        ) : (
                          <div></div>
                        )}
                      </div>
                      {post.blockquote === "" ? (
                        <div></div>
                      ) : (
                        <blockquote>
                          <p>References</p>
                          {post.blockquote}
                          <a href={post.link} target="_blank" rel="noreferrer">
                            {post.link}
                          </a>
                        </blockquote>
                      )}
                    </div>
                  </div>
                  <div className="post-options">
                    <div className="clearfix">
                      <div className="share-options clearfix">
                        <strong>Share it on - </strong>
                        <ul className="social-links-one">
                          <li>
                            <a href="/">
                              <span className="fa fa-facebook-f"></span>
                            </a>
                          </li>
                          <li>
                            <a href="/">
                              <span className="fa fa-twitter"></span>
                            </a>
                          </li>
                          <li>
                            <a href="/">
                              <span className="fa fa-linkedin"></span>
                            </a>
                          </li>
                        </ul>
                      </div>
                      {}
                      <div className="next-option">
                        <Link to={`/${nextPost.title}`}>
                          Next Post
                          <span className="fas fa-long-arrow-alt-right"></span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <h2> Sorry. Post can't be found </h2>
                </div>
              )}
            </section>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BlogPost;
