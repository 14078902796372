import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import BlogData from "./Blog.json";
import AutoSuggest from "../AutoSuggest/AutoSuggest";

import "../../App.css";
import "./Blog.css";

function Blog() {
  const [data, setData] = useState([]);

  // fetch(BlogData)
  // .then((res) => res.json())
  // .then((data) => {
  //   console.log("data", data);
  //   if (isMounted) setData(BlogData);
  // })
  // .catch((err) => {
  //   console.log(err);
  // });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // console.log(data);
      return setData(BlogData);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div>
      <section id="startchange" className="page-title">
        <div className="auto-container">
          <h1>Blog</h1>
          <ul className="page-breadcrumb">
            <li className="firstLi">
              <a href="./">Home</a>
            </li>
            <li>Blog</li>
          </ul>
        </div>
        <div className="custom-shape-divider-bottom-1607429966">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              className="shape-fill"
            ></path>
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              className="shape-fill"
            ></path>
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </section>

      <section className="sidebar-page-container container blog-page">
        <div className="row">
          {/* <div className="form sidebar-widget search-box col-lg-8 col-md-8 col-sm-12 col-xs-12 mx-3 mx-md-0">
            <div className="input-group mb-0 mb-md-5">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <div className="input-group-append">
                <button className="btn btn-outline-secondary" type="button">
                  Button
                </button>
              </div>
            </div>
          </div> */}
          <AutoSuggest />
        </div>
        <div className="row">
          <div className="content-side col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              {data ? (
                data.map((data, i) => {
                  return (
                    <div
                      key={i}
                      className="news-style-one col-md-4 col-sm-6 col-xs-12"
                    >
                      <div className="inner-box">
                        <figure className="image-box">
                          <Link to={`/${data.title}`}>
                            <img
                              className="img-fluid"
                              src={data.img}
                              alt={data.title}
                            />
                          </Link>
                        </figure>
                        <div className="lower-content">
                          <div className="post-meta">{data.author}</div>
                          <h3>
                            <Link to={`/${data.title}`}>{data.title}</Link>
                          </h3>
                          <div className="text">{data.text}</div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="col-12">
                  <p>No Content at the moment. Please check back later</p>
                </div>
              )}
            </div>
          </div>
          {/* <div className="sidebar-side col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <aside className="row sidebar">             
            </aside>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default Blog;
