import React from "react";

import "../../App.css";
import "./DigiTrans.css";

function DigiTrans() {
  return (
    <div>
      <section id="startchange" className="page-title">
        <div className="auto-container">
          <h1>Digital Transformation</h1>
          <ul className="page-breadcrumb">
            <li className="firstLi">
              <a href="./">Home</a>
            </li>
            <li>Digital Transformation</li>
          </ul>
        </div>
        <div className="custom-shape-divider-bottom-1607429966">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              className="shape-fill"
            ></path>
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              className="shape-fill"
            ></path>
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </section>

      <section className="services-page-section container-fluid">
        <div className="auto-container container">
          {/* <p>
          We are experienced digital transformation partners. In the
          everchanging world of technology, it is important that the right
          decisions are made when it comes to using technology as an enabler to
          achieving strategic objectives. Optisource Technologies has the skill
          and experience to deliver full digital transformations or improve
          current service in response to a constantly changing landscape.
        </p> */}
          <div className="row clearfix">
            <div className="service-style-three col-md-3 col-sm-4 col-xs-12">
              <div className="inner-box">
                <div className="icon">
                  <figure className="image">
                    <i className="fas fa-cogs fa-2x"></i>
                  </figure>
                </div>
                <h3>API development</h3>
              </div>
            </div>

            <div className="service-style-three col-md-3 col-sm-4 col-xs-12">
              <div className="inner-box">
                <div className="icon">
                  <figure className="image">
                    <i className="fas fa-cloud fa-2x"></i>
                  </figure>
                </div>
                <h3>Cloud-based service development</h3>
              </div>
            </div>

            <div className="service-style-three col-md-3 col-sm-4 col-xs-12">
              <div className="inner-box">
                <div className="icon">
                  <figure className="image">
                    <i className="fas fa-newspaper fa-2x"></i>
                  </figure>
                </div>
                <h3>Content management system</h3>
              </div>
            </div>

            <div className="service-style-three col-md-3 col-sm-4 col-xs-12">
              <div className="inner-box">
                <div className="icon">
                  <figure className="image">
                    <i className="fas fa-user fa-2x"></i>
                  </figure>
                </div>
                <h3>Customer relationship management</h3>
              </div>
            </div>

            <div className="service-style-three col-md-3 col-sm-4 col-xs-12">
              <div className="inner-box">
                <div className="icon">
                  <figure className="image">
                    <i className="fas fa-database fa-2x"></i>
                  </figure>
                </div>
                <h3>Database development</h3>
              </div>
            </div>

            <div className="service-style-three col-md-3 col-sm-4 col-xs-12">
              <div className="inner-box">
                <div className="icon">
                  <figure className="image">
                    <i className="fas fa-desktop fa-2x"></i>
                  </figure>
                </div>
                <h3>Desktop application development</h3>
              </div>
            </div>

            <div className="service-style-three col-md-3 col-sm-4 col-xs-12">
              <div className="inner-box">
                <div className="icon">
                  <figure className="image">
                    <i className="fas fa-gamepad fa-2x"></i>
                  </figure>
                </div>
                <h3>Game development</h3>
              </div>
            </div>

            <div className="service-style-three col-md-3 col-sm-4 col-xs-12">
              <div className="inner-box">
                <div className="icon">
                  <figure className="image">
                    <i className="fas fa-envelope-open-text fa-2x"></i>
                  </figure>
                </div>
                <h3>Message queues</h3>
              </div>
            </div>

            <div className="service-style-three col-md-3 col-sm-4 col-xs-12">
              <div className="inner-box">
                <div className="icon">
                  <figure className="image">
                    <i className="fas fa-mobile fa-2x"></i>
                  </figure>
                </div>
                <h3>Mobile app development</h3>
              </div>
            </div>

            <div className="service-style-three col-md-3 col-sm-4 col-xs-12">
              <div className="inner-box">
                <div className="icon">
                  <figure className="image">
                    <i className="fas fa-search fa-2x"></i>
                  </figure>
                </div>
                <h3>Search</h3>
              </div>
            </div>

            <div className="service-style-three col-md-3 col-sm-4 col-xs-12">
              <div className="inner-box">
                <div className="icon">
                  <figure className="image">
                    <i className="fas fa-signal fa-2x"></i>
                  </figure>
                </div>
                <h3>Systems integration</h3>
              </div>
            </div>

            <div className="service-style-three col-md-3 col-sm-4 col-xs-12">
              <div className="inner-box">
                <div className="icon">
                  <figure className="image">
                    <i className="fas fa-globe-americas fa-2x"></i>
                  </figure>
                </div>
                <h3>Web app development</h3>
              </div>
            </div>

            <div className="service-style-three col-md-3 col-sm-4 col-xs-12">
              <div className="inner-box">
                <div className="icon">
                  <figure className="image">
                    <i className="fas fa-key fa-2x"></i>
                  </figure>
                </div>
                <h3>Accessibility</h3>
              </div>
            </div>

            <div className="service-style-three col-md-3 col-sm-4 col-xs-12">
              <div className="inner-box">
                <div className="icon">
                  <figure className="image">
                    <i className="fas fa-film fa-2x"></i>
                  </figure>
                </div>
                <h3>Animation</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="features-section-two" id="features">
        <div className="auto-container">
          <div className="features-container clearfix">
            <div className="row clearfix">
              <div className="text-column col-md-4 col-sm-12 col-xs-12">
                <div className="inner">
                  <h2>SUPPORT SERVICES</h2>
                  <div className="text">
                    We are experienced digital transformation partners. In the
                    everchanging world of technology, it is important that the
                    right decisions are made when it comes to using technology
                    as an enabler to achieving strategic objectives. Optisource
                    Technologies has the skill and experience to deliver full
                    digital transformations or improve current service in
                    response to a constantly changing landscape.
                  </div>
                  <div className="more-link-one">
                    <a
                      href="#/contact-us"
                      autoScroll="true"
                      className="theme-btn"
                    >
                      {/* <Link to="/contact-us" autoScroll="true"> */}
                      Reach Out{" "}
                      <span className="icon ti-arrow-circle-right"></span>
                      {/* </Link> */}
                    </a>
                  </div>
                </div>
              </div>

              <div className="features-column col-md-8 col-sm-12 col-xs-12">
                <div className="inner">
                  <div className="row clearfix">
                    <div className="feature-block-two col-md-6 col-sm-6 col-xs-12">
                      <div className="inner-box">
                        <div className="icon">
                          <figure className="image">
                            <img
                              src="./digiTrans/digi1.jpg"
                              alt="prototyping"
                            />
                          </figure>
                        </div>
                        <h3>Prototyping</h3>
                      </div>
                    </div>
                    <div className="feature-block-two col-md-6 col-sm-6 col-xs-12">
                      <div className="inner-box">
                        <div className="icon">
                          <figure className="image">
                            <img
                              src="./digiTrans/digi2.png"
                              alt="Cross-platform design"
                            />
                          </figure>
                        </div>
                        <h3>Cross-platform design</h3>
                      </div>
                    </div>
                    <div className="feature-block-two col-md-6 col-sm-6 col-xs-12">
                      <div className="inner-box">
                        <div className="icon">
                          <figure className="image">
                            <img
                              src="./digiTrans/digi3.jpg"
                              alt="Information architecture"
                            />
                          </figure>
                        </div>
                        <h3>Information architecture</h3>
                      </div>
                    </div>
                    <div className="feature-block-two col-md-6 col-sm-6 col-xs-12">
                      <div className="inner-box">
                        <div className="icon">
                          <figure className="image">
                            <img
                              src="./digiTrans/digi4.jpg"
                              alt="Interaction design"
                            />
                          </figure>
                        </div>
                        <h3>Interaction design</h3>
                      </div>
                    </div>
                    <div className="feature-block-two col-md-6 col-sm-6 col-xs-12">
                      <div className="inner-box">
                        <div className="icon">
                          <figure className="image">
                            <img
                              src="./digiTrans/digi5.png"
                              alt="User experience & design strategy"
                            />
                          </figure>
                        </div>
                        <h3>User experience & design strategy</h3>
                      </div>
                    </div>
                    <div className="feature-block-two col-md-6 col-sm-6 col-xs-12">
                      <div className="inner-box">
                        <div className="icon">
                          <figure className="image">
                            <img
                              src="./digiTrans/digi6.jpg"
                              alt="Content design and copywriting"
                            />
                          </figure>
                        </div>
                        <h3>Content design and copywriting</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="call-to-action">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="text-column col-lg-7 col-md-12 col-sm-12 col-xs-12">
              <div className="inner">
                <h2>GET HELP WITH YOUR PROJECT!</h2>
                <div className="text">
                  Answers and advice from people you want it from. What are you
                  waiting for?
                </div>
              </div>
            </div>

            <div className="links-column col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <div className="inner">
                <a href="#/about-us" className="theme-btn btn-style-one">
                  Learn More
                </a>
                <a href="#/contact-us" className="theme-btn btn-style-four">
                  Get in Touch{" "}
                  <span className="icon ti-arrow-circle-right"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DigiTrans;
