import React from "react";
import Autosuggest from "react-autosuggest";
import { withRouter } from "react-router-dom";

import BlogData from "../Blog/Blog.json";

import "./AutoSuggest.css";

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function getSuggestions(value) {
  const escapedValue = escapeRegexCharacters(value.trim());

  if (escapedValue === "") {
    return [];
  }

  const regex = new RegExp("^" + escapedValue, "i");

  return BlogData.filter((post) => regex.test(post.title));
}

function getSuggestionValue(suggestion) {
  return suggestion.title;
}

function renderSuggestion(suggestion) {
  return <span>{suggestion.title}</span>;
}

class AutoSuggest extends React.Component {
  constructor() {
    super();

    this.state = {
      value: "",
      suggestions: [],
      redirect: null,
    };
    this.HandleSuggestionClick = this.HandleSuggestionClick.bind(this);
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  HandleSuggestionClick(e, { suggestion }) {
    let path = `${suggestion.title}`;
    this.props.history.push(path);
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Search for a blog post",
      value: value.toString(),
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={this.HandleSuggestionClick}
      />
    );
  }
}
export default withRouter(AutoSuggest);
