import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import "../App.css";

function Home() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div>
      <div id="startchange" className="jumbotron jumbotron-fluid mb-0 hero">
        <div className="container pt-md-5">
          <h1 className="display-45">
            Making Technology <br />
            work for Business
          </h1>
          <p className="">
            With the skill and experience, we deliver full digital
            transformations or improve current service in response to a
            constantly changing landscape.
          </p>
          <Link to="/digital-transformation">
            <button className="btn shadow px-5 py-3 mb-5 rounded">
              Learn More
            </button>
          </Link>
        </div>
      </div>

      <section className="about_us">
        <div className="jumbotron jumbotron-fluid mb-0">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <img
                  src="./images/about-us.png"
                  className="img-fluid"
                  alt="about us"
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <h3 className="display-5">About Us</h3>
                <p className="lead">
                  Optisource Technology is a network of highly experienced
                  digital specialists with several years of experience in
                  digital transformation and software delivery. We have over 10
                  years experience in the industry and together with our
                  strategic partners have 100 years of combined experience at
                  the last count. We are an Institute for Security and Open
                  Methodologies (ISECOM ) Silver Member.
                </p>
                <a href="#/about-us">
                  <button className="btn shadow px-5 py-3 rounded">
                    Learn More
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="what_we_offer">
        <div className="jumbotron-fluid mb-0">
          <h3 className="display-5 text-center mt-5 py-4">What we offer</h3>
          <div className="row">
            <div className="container">
              <div
                className="row mb-5 py-5 row1"
                style={{ maxWidth: "1200px", margin: "0 auto" }}
              >
                <div className="col-md-5 col-sm-12 mr-auto">
                  <div className="row header">
                    <div className="col-2">
                      <img
                        src="./images/stars.png"
                        width="50"
                        className="img-fluid"
                        alt="stars"
                      />
                    </div>
                    <div className="col-10">
                      <h4 className="display-5">
                        Web and Mobile <br />
                        Application Development
                      </h4>
                      <hr />
                      <p className="lead my-4">
                        From simple Content Management System to complex
                        eCommerce developer, We design professional looking yet
                        simple websites. Our designs are search engine and user
                        friendly.
                      </p>
                      <a href="#/digital-transformation">
                        <button className="btn shadow px-5 py-3 rounded">
                          Learn More
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-sm-12"
                  data-aos="fade-left"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  <img src="./images/web.png" className="img-fluid" alt="web" />
                </div>
              </div>
            </div>

            <div
              className="container-fluid"
              style={{ backgroundColor: "#e9ecef" }}
            >
              <div
                className="row my-5 py-5 row1"
                style={{ maxWidth: "1200px", margin: "0 auto" }}
              >
                <div
                  className="col-md-6 col-sm-12"
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                >
                  <img src="./images/QA.png" className="img-fluid" alt="QA" />
                </div>
                <div className="col-md-5 col-sm-12 mr-auto">
                  <div className="row header">
                    <div className="col-2">
                      <img
                        src="./images/stars.png"
                        width="50"
                        className="img-fluid"
                        alt="stars"
                      />
                    </div>
                    <div className="col-10">
                      <h4 className="display-5">
                        Independent Quality Assurance of Solutions and
                        Infrastructure.
                      </h4>
                      <hr />
                      <p className="lead my-4">
                        From simple Content Management System to complex
                        eCommerce developer, We design professional looking yet
                        simple websites. Our designs are search engine and user
                        friendly.
                      </p>
                      <a href="#/digital-transformation">
                        <button className="btn shadow px-5 py-3 rounded">
                          Learn More
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div
                className="row my-5 py-5 row1"
                style={{ maxWidth: "1200px", margin: "0 auto" }}
              >
                <div className="col-md-5 col-sm-12 mr-auto">
                  <div className="row header">
                    <div className="col-2">
                      <img
                        src="./images/stars.png"
                        width="50"
                        className="img-fluid"
                        alt="stars"
                      />
                    </div>
                    <div className="col-10">
                      <h4 className="display-5">
                        Delivering Digital Transformation Solutions to improve
                        productivity.
                      </h4>
                      <hr />
                      <p className="lead my-4">
                        From simple Content Management System to complex
                        eCommerce developer, We design professional looking yet
                        simple websites. Our designs are search engine and user
                        friendly.
                      </p>
                      <a href="#/digital-transformation">
                        <button className="btn shadow px-5 py-3 rounded">
                          Learn More
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-sm-12"
                  data-aos="fade-left"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="500"
                >
                  <img
                    src="./images/digital.png"
                    className="img-fluid"
                    alt="digital"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our_partners">
        <div className="jumbotron mb-0">
          <div className="container p-5">
            <h3 className="display-5 text-center py-5">Our Partners</h3>
            <div className="row">
              <div className="col-md-4 col-sm-12 text-sm-center mb-sm-3">
                <img
                  src="./images/microsoft.png"
                  className="img-fluid"
                  alt="Microsoft Partner"
                />
              </div>
              <div className="col-md-4 col-sm-12 text-sm-center mb-sm-3">
                <img
                  src="./images/solarwind.png"
                  className="img-fluid"
                  alt="Solar Wind"
                />
              </div>
              <div className="col-md-4 col-sm-12 text-sm-center mb-sm-3">
                <img
                  src="./images/google.jpg"
                  className="img-fluid"
                  alt="Google partner"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact_us">
        <div className="jumbotron jumbotron-fluid mb-0">
          <div className="container my-4">
            <div className="row">
              <div className="col-12 text-center">
                <h3 className="display-5">Have a project in mind?</h3>
                <Link to="/contact-us">
                  <button className="btn shadow px-5 py-3 m-3 rounded">
                    Start a Conversation
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our_platforms">
        <div className="jumbotron mb-0">
          <div className="container p-5">
            <h3 className="display-5 text-center py-5">Supported Platforms</h3>
            <div className="row">
              <div className="col-md-4 col-sm-12 text-sm-center mb-sm-3">
                <img src="./images/aws.jpg" className="img-fluid" alt="aws" />
              </div>
              <div className="col-md-4 col-sm-12 text-sm-center mb-sm-3">
                <img
                  src="./images/azure.jpg"
                  className="img-fluid"
                  alt="azure"
                />
              </div>
              <div className="col-md-4 col-sm-12 text-sm-center mb-sm-3">
                <img
                  src="./images/googleCloud.jpg"
                  className="img-fluid"
                  alt="google cloud"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
