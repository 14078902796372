import React from "react";
import CountUp from "react-countup";

import "../../App.css";
import "./AboutUs.css";

function AboutUs() {
  const easingFn = (t, b, c, d) => {
    const ts = (t /= d) * t;
    const tc = ts * t;
    return b + c * (tc + -3 * ts + 3 * t);
  };

  return (
    <div>
      <section id="startchange" className="page-title">
        <div className="auto-container">
          <h1>About Us</h1>
          <ul className="page-breadcrumb">
            <li className="firstLi">
              <a href="./">Home</a>
            </li>
            <li>About Us</li>
          </ul>
        </div>
        <div className="custom-shape-divider-bottom-1607429966">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              className="shape-fill"
            ></path>
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              className="shape-fill"
            ></path>
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </section>

      <div className="about">
        <div className="welcome-message mt-5">
          <h1>WELCOME</h1>
          <p className="lead">
            We are Optisource Technology Solutions, a network of{" "}
            <strong style={{ fontWeight: 600 }}>IT Professionals </strong>{" "}
            <br />
            and digital specialists eager to make your business stand out.
          </p>
        </div>

        <div className="container img">
          <img
            src="/images/happy-customers.jpg"
            className="img-fluid"
            alt="about us"
          />
        </div>

        <div className="container mission_vision my-md-5 my-5">
          <div className="row justify-content-between">
            <div
              className="column col-md-6 col-sm-12 p-5"
              style={{ backgroundColor: "antiquewhite" }}
            >
              <h3>Our Mission</h3>
              <p>
                Our mission is simply to
                <em>
                  "Make Technology Work for Business while Enabling people and
                  businesses to achieve their full potential".
                </em>
                We aim to be a partner that supports organisations to achieve
                their full digital transformational potentials. We are not just
                an agency. We go beyond turnkey solutions and partner with
                companies on their journey to fully fledged digital solutions.
              </p>
            </div>
            <div
              className="column col-md-6 col-sm-12 p-5"
              style={{ backgroundColor: "#e9ecef" }}
            >
              <h3>Our Vision</h3>
              <p>
                <ul>
                  Our vision is to be a recognised name in three core areas;
                  <br />
                  <li>To be the premier partner in Digital transformation </li>
                  <li>
                    To provide independent Software quality assesment and
                    assurance{" "}
                  </li>
                  <li>
                    To be at the forefront of protecting organisations by
                    bringing our expertise in Cybersecurity to the table.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="jumbotron-fluid achievements my-md-5 py-5">
          <div className="container text-center">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-3 mt-2">
                <span
                  className="counter-count timer count-title count-number"
                  data-to="15"
                  data-speed="2000"
                >
                  <CountUp duration={10} end={15} easingFn={easingFn} />
                </span>
                <span>+</span>
                <h4 className="title">Clients</h4>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3 mt-2">
                <span
                  className="counter-count timer count-title count-number"
                  data-to="30"
                  data-speed="2000"
                >
                  <CountUp duration={10} end={30} easingFn={easingFn} />
                </span>
                <span>+</span>
                <h4 className="title">Finished Projects</h4>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3 mt-2">
                <span
                  className="counter-count timer count-title count-number"
                  data-to="100"
                  data-speed="2000"
                >
                  <CountUp duration={10} end={100} easingFn={easingFn} />
                </span>
                <span>+</span>
                <h4 className="title">Happy Users</h4>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3 mt-2">
                <span
                  className="counter-count timer count-title count-number"
                  data-to="5"
                  data-speed="2000"
                >
                  <CountUp duration={10} end={5} easingFn={easingFn} />
                </span>
                <span>+</span>
                <h4>Years of Experience</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="team container text-center my-md-5 py-5 contact_us">
          <h3 className="display-5">Have some questions?</h3>
          <button className="btn shadow px-5 py-3 m-3 rounded">
            Let's have a Conversation
          </button>
          {/* <h2>Meet Our Team</h2> */}
          {/* <div className="row">
            {Team.map((member, i) => {
              return (
                <div key={i} className="col-sm-12 col-md-6 col-lg-4">
                  <div className="card">
                    <div className="card-image">
                      <img
                        className="card-img-top avatar image"
                        src={
                          member.img !== ""
                            ? member.img
                            : member.gender === "male"
                            ? "../images/male-avatar.png"
                            : "../images/female-avatar.png"
                        }
                        alt="Card cap"
                      />
                      <div class="middle">
                        <div class="text">{member.bio}</div>
                      </div>
                    </div>
                    <div className="card-body">
                      <h3 className="card-title">
                        {member.name}
                        <a href={member.link} target="_blank">
                          <i class="fab fa-linkedin"></i>
                        </a>
                      </h3>
                      <p className="card-text">{member.position}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
